<!-- Start Categories List -->
<div class="collection-collapse-block border-0" [class.open]="collapse">
    <h3 class="collapse-block-title" (click)="collapse = !collapse">{{'categories:categories-title' | translate}}</h3>
    <div class="collection-collapse-block-content">
        <div class="collection-brand-filter">
            <ng-container *ngFor="let category of categories; let a = index">
                <div class="custom-control custom-checkbox collection-filter-checkbox" *ngIf="category.CategoryRef != 3">
                    <input type="checkbox" [value]="category.CategoryNameAlias" [checked]="checked(category.CategoryNameAlias)"
                        id="category{{category.CategoryRef}}" (change)="appliedFilter($event,'Category')"
                        class="custom-control-input" />
                    <label class="custom-control-label" for="category{{ category.CategoryRef }}">{{ category.CategoryName }}</label>
                </div>
            </ng-container>
            
        
          
        </div>

    </div>
</div>

<div class="collection-collapse-block" *ngFor="let prop of propsList; let i = index" id="OpenCollapse{{prop.PropRef}}"
    [class.open]="true">
    <h3 class="collapse-block-title" (click)="OpenCollapse(prop.PropRef)">{{prop.PropName}}</h3>
    <div class="collection-collapse-block-content">
        <div class="collection-brand-filter">
            <div class="custom-control custom-checkbox collection-filter-checkbox"
                *ngFor="let val of prop.Vals; let a = index">
                <input type="checkbox" [value]="val.PropValNameAlias" [checked]="checked(val.PropValNameAlias)"
                    [id]="val.PropValRef" (change)="appliedFilter($event,prop.PropNameAlias)"
                    class="custom-control-input" />
                <label class="custom-control-label" for="{{ val.PropValRef }}">{{ val.PropValName }}</label>
            </div>
        </div>
    </div>
</div>
<!-- End Categories List -->