import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { NavService, Menu } from '../../services/nav.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { ToastrService } from 'ngx-toastr';
import { animate, group, query, style, transition, trigger } from '@angular/animations';
import { TokenService } from '../../services/token.service';
import { isPlatformBrowser, ViewportScroller } from '@angular/common';


declare var $: any;
@Component({
  selector: 'app-menu',
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('300ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ transform: 'translateX(-100%)' }))
      ])
    ])
  ],
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})

export class MenuComponent implements OnInit {

  MenuList: Array<any> = [];
  MenuListByLanguage: Array<any> = [];
  @Input() stick: boolean;
  @Input() home: boolean;
  @Input() IsSearching: boolean;
  @Input() responsivethemeLogodark: string = 'assets/images/icon/responsive-logo.png'; // Default Logo
  lastscroll;


  isRowMenuOpen;
  isColumnMenuOpen;
  ColumnMenuItems: any = [];
  RowMenuItems: any = [];
  ActiveItem;
  ActiveItemRef;
  ActiveItemName;

  public languages = [{
    name: 'Türkçe',
    code: 'tr',
    flag: 'tr'
  }];

  constructor(private router: Router, private viewScroller: ViewportScroller, @Inject(PLATFORM_ID) private platformId: Object, public tokenService: TokenService, private http: HttpClient, public navServices: NavService, private toastr: ToastrService, private translate: TranslateService, private localize: LocalizeRouterService) {

    this.http.get<any>(environment.url + 'Menu/GetMegaMenu').subscribe(data => {
      this.MenuList = data.Data;
      data.Data.forEach(element => {
        if (element.LanguageCode == translate.currentLang.toUpperCase() && element.IsActive) {
          element.RowActive = false;
          if (element.Columns.length) {
            element.Columns.forEach(column => {
              if (column.Rows.length) {
                column.Rows.forEach(row => {
                  if (row.IsActive) element.RowActive = true;
                });
              }
            });
          }
          this.MenuListByLanguage.push(element);
        }
      });
    });
    // this.navServices.items.subscribe(menuItems => this.menuItems = menuItems);
    this.router.events.subscribe((event) => {
      this.navServices.mainMenuToggle = false;
      $("body").css({ "overflow": "" });
      $("body").css({ "position": "" });
    });
  }
  public switchLang(code) {
    this.localize.changeLanguage(code);
    this.ActiveItemName = null;
    this.ActiveItemRef = null;
    this.isRowMenuOpen = false;
    this.isColumnMenuOpen = false;
    this.ActiveItem = null;
    this.ColumnMenuItems = [];
    this.RowMenuItems = [];
  }
  ngOnInit(): void {
    this.localize.routerEvents.subscribe((language: string) => {
      this.MenuListByLanguage = [];
      this.MenuList.forEach(element => {
        if (element.LanguageCode == this.translate.currentLang.toUpperCase() && element.IsActive) {
          element.RowActive = false;
          if (element.Columns.length) {
            element.Columns.forEach(column => {
              if (column.Rows.length) {
                column.Rows.forEach(row => {
                  if (row.IsActive) element.RowActive = true;
                });
              }
            });
          }
          this.MenuListByLanguage.push(element);
        }
      });
    });
  }
  mainMenuToggle(): void {
    this.navServices.mainMenuToggle = !this.navServices.mainMenuToggle;
    if (this.navServices.mainMenuToggle) {
      this.lastscroll = document.documentElement.scrollTop;
      $("body").css({ "overflow": "hidden" });
      $("body").css({ "position": "fixed" });
    }
    else {
      $("body").css({ "overflow": "" });
      $("body").css({ "position": "" });
      this.viewScroller.scrollToPosition([0, this.lastscroll]);

    }
  }
  // Click Toggle menu (Mobile)
  OpenColumnNavActive(item) {
    this.ActiveItem = item;
    this.ColumnMenuItems = [];

    item.Columns.forEach(element => {
      element.Rows.forEach(row => {
        if (row.FontStyle == "Bold" && row.IsActive) {
          this.ActiveItemRef = element.Ref;
          this.ColumnMenuItems.push(row);
        }
      });
    });
    console.log(this.ColumnMenuItems);
    this.isRowMenuOpen = false;
    this.isColumnMenuOpen = true;
  }
  OpenRowNavActive(item, name, ref) {
    this.RowMenuItems = [];
    this.ActiveItemName = name;
    item.Columns.forEach(element => {
      if (element.Ref == ref) {
        element.Rows.forEach(row => {
          if (row.FontStyle != "Bold" && row.IsActive) this.RowMenuItems.push(row);
        });
      }

    });

    console.log(this.RowMenuItems);
    this.isColumnMenuOpen = false;
    this.isRowMenuOpen = true;
  }

  backToMainMenu() {
    this.ActiveItemName = null;
    this.ActiveItemRef = null;
    this.isRowMenuOpen = false;
    this.isColumnMenuOpen = false;
    this.ColumnMenuItems = [];
    this.RowMenuItems = [];
  }
  backToColumnMenu() {
    this.isRowMenuOpen = false;
    this.isColumnMenuOpen = true;
    this.RowMenuItems = [];
  }

  LogOut() {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem("tokenASYA");
      localStorage.removeItem("wishlistItems");
      localStorage.removeItem("AsyaCartItems");
    }
    let translate = this.translate.translations[this.translate.currentLang];
    this.toastr.success(translate['notification:exit']);
    this.router.navigate(["/"]);
  }
}