import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, startWith, delay } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Product } from '../classes/product';
import { environment } from 'src/environments/environment';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { TokenService } from './token.service';
import { Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { isPlatformBrowser } from '@angular/common';

const state = {
  products: JSON.parse('[]'),
  wishlist: JSON.parse('[]'),
  compare: JSON.parse('[]'),
  cart: JSON.parse('[]')
}

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  public Currency = { name: 'Dollar', currency: 'USD', price: 1 } // Default Currency
  public OpenCart: boolean = false;
  public Products

  constructor(private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object,
    private toastrService: ToastrService,
    private translate: TranslateService,
    public tokenService: TokenService, private localize: LocalizeRouterService,
    private router: Router) {
  }

  /*
    ---------------------------------------------
    ---------------  Product  -------------------
    ---------------------------------------------
  */

  // Product
  private get products(): Observable<Product[]> {
    this.Products = this.http.get<Product[]>(environment.url + 'Products/GetAllProducts?LangCode=' + this.translate.currentLang).pipe(map(data => data));
    this.Products.subscribe(next => {
      if (isPlatformBrowser(this.platformId)) {
        localStorage['products'] = JSON.stringify(next)
      }
    });
    if (isPlatformBrowser(this.platformId)) {
      return this.Products = this.Products.pipe(startWith(JSON.parse(localStorage['products'] || '[]')));
    }
  }

  // Get Products
  public get getProducts(): Observable<Product[]> {
    return this.products;
  }

  // Get Products By Slug
  public getProductBySlug(slug: string) {
    // return this.products.pipe(map(items => {
    //   return items.find((item: any) => {
    //     return item.URL + '-' + slug.substring(slug.length - 3) === slug;
    //   });
    // }));
    return this.http.get<any>(environment.url + 'resolver/resolve?resolve=' + '/' + slug + '&langCode=' + this.translate.currentLang).pipe(map(data => data));
  }

  public getProductsByCategory(slug: string): Observable<Product[]> {
    return this.http.get<Product[]>(environment.url + 'Products/GetAllProductsByURL?URL=' + '/' + slug + '&LangCode=' + this.translate.currentLang).pipe(map(data => data));
  }

  // Get Products By Slug
  public async getCMSContents(slug: string): Promise<Observable<any>> {
    return await this.http.get<any>(environment.url + 'Policies/GetPoliciesPages?LanguageCode=' + this.translate.currentLang + '&link=' + '/' + slug).pipe(map(data => data));
  }
  /*
    ---------------------------------------------
    ---------------  Wish List  -----------------
    ---------------------------------------------
  */

  // Get Wishlist Items
  public get wishlistItems(): Observable<Product[]> {
    if (this.tokenService.getUser()) {
      let wishlist
      if (isPlatformBrowser(this.platformId)) {
        wishlist = localStorage.getItem("wishlistItems");
      }
      if (isPlatformBrowser(this.platformId)) {
        if (wishlist != null && wishlist.length) state.wishlist = JSON.parse(localStorage['wishlistItems']);
      }
      const itemsStream = new Observable(observer => {
        observer.next(state.wishlist);
        observer.complete();
      });
      return <Observable<Product[]>>itemsStream;
    }

  }
  public get getwishlistItems(): Observable<Product[]> {
    return state.wishlist;
  }
  // Add to Wishlist
  public addToWishlist(product): any {
    if (this.tokenService.getUser()) {
      const wishlistItem = state.wishlist.find(item => item.ProductVariant.Barcode === product.ProductVariant.Barcode)
      if (!wishlistItem) {
        const body = { StockRef: product.StockRef, VariantRef: product.ProductVariant.VariantRef, Amount: 0, Price: product.ProductVariant.CardStoreSalesPrice };
        this.http.post<any>(environment.url + 'Wish/AddToWish', body).subscribe(data => {
          if (!data.Error) {
            state.wishlist.push({
              ...product
            })

            let translate = this.translate.translations[this.translate.currentLang];
            this.toastrService.success(translate['notification:product-added-wishlist']);

            if (isPlatformBrowser(this.platformId)) {
              localStorage.setItem("wishlistItems", JSON.stringify(state.wishlist));
            }
            return true
          }
          else {
            let translate = this.translate.translations[this.translate.currentLang];
            this.toastrService.error(translate['notification-error:product-added-wishlist']);

            return false
          }
        });

      }
    }
    else {
      let translate = this.translate.translations[this.translate.currentLang];
      this.toastrService.error(translate['notification-error:only-registered-wishlist']);
      let translatedPath: any = this.localize.translateRoute('/login');
      this.router.navigate([translatedPath]);
    }
  }

  // Remove Wishlist items
  public removeWishlistItem(product): any {
    if (this.tokenService.getUser()) {
      const index = state.wishlist.indexOf(state.wishlist.find(item => item.ProductVariant.Barcode === product.ProductVariant.Barcode));
      if (index != -1) {
        const body = { StockRef: product.StockRef, VariantRef: product.ProductVariant.VariantRef, Amount: product.ProductVariant.Balance, Price: product.ProductVariant.StoreSalesPrice };
        this.http.post<any>(environment.url + 'Wish/RemoveToWish', body).subscribe(data => {
          if (!data.Error) {
            state.wishlist.splice(index, 1);
            let translate = this.translate.translations[this.translate.currentLang];
            this.toastrService.success(translate['notification:product-removed-wishlist']);

            if (isPlatformBrowser(this.platformId)) {
              localStorage.setItem("wishlistItems", JSON.stringify(state.wishlist));
            }
            return true
          }
          else {
            let translate = this.translate.translations[this.translate.currentLang];
            this.toastrService.error(translate['notification-error:product-removed-wishlist']);
            return false
          }
        });

      }
      else {
        let translate = this.translate.translations[this.translate.currentLang];
        this.toastrService.error(translate['notification-error:product-not-removed-wishlist']);
        return false
      }
    }
    else {
      let translate = this.translate.translations[this.translate.currentLang];
      this.toastrService.error(translate['notification-error:only-registered-remove-wishlist']);
      let translatedPath: any = this.localize.translateRoute('/login');
      this.router.navigate([translatedPath]);
    }


  }

  /*
    ---------------------------------------------
    -------------  Compare Product  -------------
    ---------------------------------------------
  */
  // Get Compare Items
  public get compareItems(): Observable<Product[]> {
    const itemsStream = new Observable(observer => {
      observer.next(state.compare);
      observer.complete();
    });
    return <Observable<Product[]>>itemsStream;
  }

  // Add to Compare
  public addToCompare(product): any {
    const compareItem = state.compare.find(item => item.Code === product.Code)
    if (!compareItem) {
      state.compare.push({
        ...product
      })
    }
    this.toastrService.success('Product has been added in compare.');
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem("compareItems", JSON.stringify(state.compare));
    }
    return true
  }

  // Remove Compare items
  public removeCompareItem(product: Product): any {
    const index = state.compare.indexOf(product);
    state.compare.splice(index, 1);
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem("compareItems", JSON.stringify(state.compare));
    }
    return true
  }

  /*
    ---------------------------------------------
    -----------------  Cart  --------------------
    ---------------------------------------------
  */

  // Get Cart Items
  public get cartItems(): Observable<Product[]> {
    if (this.tokenService.getUser()) {
      let cartitems
      if (isPlatformBrowser(this.platformId)) {
        cartitems = localStorage.getItem("AsyaCartItems");
      }
      if (isPlatformBrowser(this.platformId)) {
        if (cartitems != null && cartitems.length) state.cart = JSON.parse(localStorage['AsyaCartItems']);
      }
      const itemsStream = new Observable(observer => {
        observer.next(state.cart);
        observer.complete();
      });
      return <Observable<Product[]>>itemsStream;
    }

  }
  public cartItemsAmount(): Observable<number> {
    if (this.tokenService.getUser()) {
      return this.cartItems.pipe(map((product: Product[]) => {
        return product.reduce((prev, curr: Product) => {
          let amount = curr.CartAmount;
          return prev + amount;
        }, 0);
      }));
    }
  }


  // Add to Cart
  public addToCart(product): any {
    if (this.tokenService.getUser()) {
      if(product.ProductVariant.Balance == 0) product.IsSoldOut = true;
      else product.IsSoldOut = false;
      if (product.IsSoldOut) {
        this.http.get<any>(environment.url + 'FirmMultipler/GetFirmMultipler').subscribe(data => {
          const cartItem = state.cart.find(item => item.ProductVariant.Barcode === product.ProductVariant.Barcode);
          let qty: number = 0;
          if (cartItem) {
            qty = product.CartAmount ? product.CartAmount : product.SetCount;
          }
          else {
            if (product.CartAmount == 0) {
              product.CartAmount = product.SetCount;
            }
          }
          const items = cartItem ? cartItem : product;
          const stock = this.calculateStockCounts(items, qty);
          if (!stock) return false
          if (cartItem) {
            cartItem.CartAmount += qty
            const body = { StockRef: product.StockRef, VariantRef: product.ProductVariant.VariantRef, Amount: cartItem.CartAmount, Price: product.ProductVariant.StoreSalesPrice, IsSoldOut: product.IsSoldOut };
            this.http.post<any>(environment.url + 'Cart/AddToCart', body).subscribe(data => {
              if (!data.Error) {
                this.OpenCart = true; // If we use cart variation modal
                state.cart.find((items, index) => {
                  if (items.ProductVariant.Barcode === product.ProductVariant.Barcode) {
                    state.cart[index].CartAmount = cartItem.CartAmount;
                    if (isPlatformBrowser(this.platformId)) {
                      localStorage.setItem("AsyaCartItems", JSON.stringify(state.cart));
                    }
                  }
                });

                let translate = this.translate.translations[this.translate.currentLang];
                this.toastrService.success(translate['notification:product-added-cart']);
                return true;
              }
              else {
                let translate = this.translate.translations[this.translate.currentLang];
                this.toastrService.error(translate['notification-error:product-added-cart']);
                return false
              }
            });
          } else {
            if(product.IsSoldOutPage) items.CartAmount *= data.MinPurchaseSetAmountForSoldOut;
            const body = { StockRef: product.StockRef, VariantRef: product.ProductVariant.VariantRef, Amount: items.CartAmount, Price: product.ProductVariant.StoreSalesPrice, IsSoldOut: product.IsSoldOut };
            this.http.post<any>(environment.url + 'Cart/AddToCart', body).subscribe(data => {
              if (!data.Error) {
                state.cart.push({
                  ...product
                })
                this.OpenCart = true; // If we use cart variation modal
                let translate = this.translate.translations[this.translate.currentLang];
                this.toastrService.success(translate['notification:product-added-cart']);

                if (isPlatformBrowser(this.platformId)) {
                  localStorage.setItem("AsyaCartItems", JSON.stringify(state.cart));
                }
                return true;
              }
              else {
                let translate = this.translate.translations[this.translate.currentLang];
                this.toastrService.error(translate['notification-error:product-removed-cart']);

                return false
              }
            });

          }
        });
      }
      else {
        const cartItem = state.cart.find(item => item.ProductVariant.Barcode === product.ProductVariant.Barcode);
        let qty: number = 0;
        if (cartItem) {
          qty = product.CartAmount ? product.CartAmount : product.SetCount;
        }
        else {
          if (product.CartAmount == 0) {
            product.CartAmount = product.SetCount;
          }
        }
        const items = cartItem ? cartItem : product;
        const stock = this.calculateStockCounts(items, qty);
        if (!stock) return false
        if (cartItem) {
          cartItem.CartAmount += qty
          const body = { StockRef: product.StockRef, VariantRef: product.ProductVariant.VariantRef, Amount: cartItem.CartAmount, Price: product.ProductVariant.StoreSalesPrice, IsSoldOut: product.IsSoldOut };
          this.http.post<any>(environment.url + 'Cart/AddToCart', body).subscribe(data => {
            if (!data.Error) {
              this.OpenCart = true; // If we use cart variation modal
              state.cart.find((items, index) => {
                if (items.ProductVariant.Barcode === product.ProductVariant.Barcode) {
                  state.cart[index].CartAmount = cartItem.CartAmount;
                  if (isPlatformBrowser(this.platformId)) {
                    localStorage.setItem("AsyaCartItems", JSON.stringify(state.cart));
                  }
                }
              });

              let translate = this.translate.translations[this.translate.currentLang];
              this.toastrService.success(translate['notification:product-added-cart']);
              return true;
            }
            else {
              let translate = this.translate.translations[this.translate.currentLang];
              this.toastrService.error(translate['notification-error:product-added-cart']);
              return false
            }
          });
        } else {
          const body = { StockRef: product.StockRef, VariantRef: product.ProductVariant.VariantRef, Amount: items.CartAmount, Price: product.ProductVariant.StoreSalesPrice, IsSoldOut: product.IsSoldOut };
          this.http.post<any>(environment.url + 'Cart/AddToCart', body).subscribe(data => {
            if (!data.Error) {
              state.cart.push({
                ...product
              })
              this.OpenCart = true; // If we use cart variation modal
              let translate = this.translate.translations[this.translate.currentLang];
              this.toastrService.success(translate['notification:product-added-cart']);

              if (isPlatformBrowser(this.platformId)) {
                localStorage.setItem("AsyaCartItems", JSON.stringify(state.cart));
              }
              return true;
            }
            else {
              let translate = this.translate.translations[this.translate.currentLang];
              this.toastrService.error(translate['notification-error:product-removed-cart']);

              return false
            }
          });

        }
      }

    }
    else {
      let translate = this.translate.translations[this.translate.currentLang];
      this.toastrService.error(translate['notification-error:only-registered-cart']);
      let translatedPath: any = this.localize.translateRoute('/login');
      this.router.navigate([translatedPath]);
    }

  }

  // Update Cart Quantity
  public updateCartQuantity(product: Product, SetCount: number): Product | boolean {
    return state.cart.find((items, index) => {
      if (items.Code === product.Code) {
        const qty = state.cart[index].CartAmount + SetCount
        const stock = this.calculateStockCounts(state.cart[index], 0)
        if (qty !== 0 && stock) {
          state.cart[index].CartAmount = qty

        }
        if (isPlatformBrowser(this.platformId)) {
          localStorage.setItem("AsyaCartItems", JSON.stringify(state.cart));
        }
        return true
      }
    })
  }
  // Update Cart Amount
  public UpdateAmount(product) {
    return state.cart.find((items, index) => {
      if (items.ProductVariant.Barcode === product.ProductVariant.Barcode) {
        const qty = product.CartAmount
        const stock = this.calculateStockCounts(state.cart[index], 0)
        if (qty !== 0 && stock) {
          const body = { StockRef: product.StockRef, VariantRef: product.ProductVariant.VariantRef, Amount: product.CartAmount, Price: product.ProductVariant.StoreSalesPrice };
          this.http.post<any>(environment.url + 'Cart/UpdateCart', body).subscribe(data => {
            if (!data.Error) {
              state.cart[index].CartAmount = qty

              if (isPlatformBrowser(this.platformId)) {
                localStorage.setItem("AsyaCartItems", JSON.stringify(state.cart));
              }
              return true;
            }
            else {
              let translate = this.translate.translations[this.translate.currentLang];
              this.toastrService.error(translate['notification:update-amount']);
              return false
            }
          });
        }
      }
    })
  }

  // Calculate Stock Counts
  public calculateStockCounts(product, Quantity) {
    const qty = product.CartAmount + Quantity
    const stock = product.ProductVariant.Balance;
    if(product.ProductVariant.Balance == 0) product.IsSoldOut = true;
    if ((stock < qty || stock == 0) && !product.IsSoldOut) {
      let translate = this.translate.translations[this.translate.currentLang];
      // this.toastrService.error(translate['notification-error:product-add-more-item'] + stock);
      this.toastrService.error(translate['notification-error:product-add-more-item']);
      return false
    }
    return true
  }
  // Calculate Stock Counts
  public calculateStockCountsincart(product, Quantity) {
    const qty = Quantity;
    const stock = product.ProductVariant.Balance;
    if(product.ProductVariant.Balance == 0) product.IsSoldOut = true;
    if ((stock < qty || stock == 0) && !product.IsSoldOut) {
      
      let translate = this.translate.translations[this.translate.currentLang];
      // this.toastrService.error(translate['notification-error:product-add-more-item'] + stock);
      this.toastrService.error(translate['notification-error:product-add-more-item']);
      return false
    }
    return true
  }
  // Remove Cart items
  public removeCartItem(product): any {
    if (this.tokenService.getUser()) {
      const index = state.cart.indexOf(state.cart.find(item => item.ProductVariant.Barcode === product.ProductVariant.Barcode));
      if (index != -1) {
        const body = { StockRef: product.StockRef, VariantRef: product.ProductVariant.VariantRef, Amount: product.CartAmount, Price: product.ProductVariant.StoreSalesPrice };
        this.http.post<any>(environment.url + 'Cart/RemoveToCart', body).subscribe(data => {
          if (!data.Error) {
            state.cart.splice(index, 1);
            if (isPlatformBrowser(this.platformId)) {
              localStorage.setItem("AsyaCartItems", JSON.stringify(state.cart));
            }
            let translate = this.translate.translations[this.translate.currentLang];
            this.toastrService.success(translate['notification:product-removed']);
            return true
          }
          else {
            let translate = this.translate.translations[this.translate.currentLang];
            this.toastrService.error(translate['notification-error:product-removed']);
            return false
          }
        });

      }
      else {
        let translate = this.translate.translations[this.translate.currentLang];
        this.toastrService.error(translate['notification-error:product-not-removed']);
        return false
      }
    }
    else {
      let translate = this.translate.translations[this.translate.currentLang];
      this.toastrService.error(translate['notification-error:only-registered-product-removed-cart']);
      let translatedPath: any = this.localize.translateRoute('/login');
      this.router.navigate([translatedPath]);
    }
  }

  // Total amount 
  public cartTotalAmount(): Observable<number> {
    return this.cartItems.pipe(map((product: Product[]) => {
      return product.reduce((prev, curr: Product) => {
        let price = curr.ProductVariant.StoreSalesPrice;
        if (curr.discount) {
          price = curr.ProductVariant.StoreSalesPrice - (curr.ProductVariant.StoreSalesPrice * curr.discount / 100)
        }
        return (prev + price * curr.CartAmount) * this.Currency.price;
      }, 0);
    }));
  }



  /*
    ---------------------------------------------
    ------------  Filter Product  ---------------
    ---------------------------------------------
  */

  // Get Product Filter
  public filterProducts(filter: any): Observable<Product[]> {
    return this.products.pipe(map(product =>
      product.filter((item: Product) => {
        if (!filter.length) return true
        const Tags = filter.some((prev) => { // Match Tags
          if (item.tags) {
            if (item.tags.includes(prev)) {
              return prev
            }
          }
        })
        return Tags
      })
    ));
  }

  // Sorting Filter
  public sortProducts(products: Product[], payload: string): any {

    if (payload === 'ascending') {
      return products.sort((a, b) => {
        if (a.Code < b.Code) {
          return -1;
        } else if (a.Code > b.Code) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'a-z') {
      return products.sort((a, b) => {
        if (a.Name < b.Name) {
          return -1;
        } else if (a.Name > b.Name) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'z-a') {
      return products.sort((a, b) => {
        if (a.Name > b.Name) {
          return -1;
        } else if (a.Name < b.Name) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'low') {
      return products.sort((a, b) => {
        if (a.ProductVariant.StoreSalesPrice < b.ProductVariant.StoreSalesPrice) {
          return -1;
        } else if (a.ProductVariant.StoreSalesPrice > b.ProductVariant.StoreSalesPrice) {
          return 1;
        }
        return 0;
      })
    } else if (payload === 'high') {
      return products.sort((a, b) => {
        if (a.ProductVariant.StoreSalesPrice > b.ProductVariant.StoreSalesPrice) {
          return -1;
        } else if (a.ProductVariant.StoreSalesPrice < b.ProductVariant.StoreSalesPrice) {
          return 1;
        }
        return 0;
      })
    }
  }

  /*
    ---------------------------------------------
    ------------- Product Pagination  -----------
    ---------------------------------------------
  */
  public getPager(totalItems: number, currentPage: number = 1, pageSize: number = 18) {
    // calculate total pages
    
    let totalPages = Math.ceil(totalItems / pageSize);

    // Paginate Range
    let paginateRange = 4;

    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    let startPage: number, endPage: number;
    if (totalPages <= 6) {
      startPage = 1;
      endPage = totalPages;
    } else if (currentPage < paginateRange - 1) {
      startPage = 1;
      endPage = startPage + paginateRange - 1;
    } else if (totalPages == currentPage) {
      startPage = currentPage - 2;
      endPage = currentPage;
    } else {
      startPage = currentPage - 1;
      endPage = currentPage + 1;
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }

}
