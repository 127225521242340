<div class="icon-nav">
  <ul class="responsive">
    <ng-container *ngIf="tokenService.getUser()">
      <li class="mega-menu mobile-d-block" style="display: none;	white-space: nowrap;padding: 0;" *ngIf="tokenService.getUser().CanUseCreditCardPayment == 'True'">
        <a class="nav-link ng-tns-c58-0 ng-star-inserted" target="_self" href="/tr/kredi-karti-ile-ode"
          style="	color: rgb(16, 107, 203);font-weight: 500;padding: 0 10px;"> Ödeme Yap </a>
      </li>
    </ng-container>
    <li class="onhover-div onhover-dropdown mobile-search search-widgets" id="search-widgets">
      <div>
        <a (click)="OpenSearchModal()"><img src="assets/images/icon/search.svg" [ngClass]="{'menu-icon-filter': sticky}"
            style="width: 18px;" class="img-fluid" alt="">
          <i class="ti-search" [ngClass]="{'responsive-color-white': !sticky && home && !IsSearching}"></i></a>
      </div>
      <div *ngIf="IsSearching" class="search-popup" [@slideIn]>
        <div class="container padding-0">
          <section class="search-block">
            <div class="container padding-0">
              <a (click)="CloseTheSearch()" class="cancel-icon"><i class="fa fa-times" style="color: black;"></i></a>
              <div class="row search-row padding-0" style="padding: 30px 100px;">
                <div class="col-lg-12">
                  <div class="form-header">
                    <div class="form-group">
                      <input type="text" class="form-control" debounce="5000" (keydown.enter)="gotoSearch()"
                        style="color: black;font-size: 14px;height: 45px;" [(ngModel)]="SearchModel.SearchText"
                        (ngModelChange)="searchBar()" placeholder="{{'search:search-place-holder' | translate}}">
                    </div>
                    <button type="submit" (click)="searchBar()"><i class="fa fa-search"
                        style="color: black;"></i></button>
                    <span class="cancel-text">
                      <a (click)="ClearSearch()" style="color: black;">
                        {{'search:search-clear-text' | translate}}
                      </a>

                    </span>
                  </div>
                </div>
              </div>

            </div>
          </section>
          <div class="row search-products" style="padding: 0 200px;" id="style-3"
            *ngIf="SearchProducts.length && Searching && !IsLoadingSearch">
            <div class="col-lg-3 mb-3" *ngFor="let item of SearchProducts">
              <a [routerLink]="[item?.URL + '-' + item.VariantCode | localize]">
                <img [src]="item.Images[0].ImageLocation" style="width: 100%;display: block;" />


                <h2
                  style="font-size: 13px;line-height: 20px;color: black;text-align: left;font-weight: 500;margin-top: 10px;">
                  {{ item.Name
                  }} <span style="color: black;font-size: 13px;font-weight: 400;">{{item.FirmModelCode}} -
                    {{item.VariantCode}}</span></h2>
              </a>

            </div>
          </div>
          <div class="col-lg-12" style="display: flex;justify-content: center;">
            <span *ngIf="IsLoadingSearch" class="spinner-border spinner-border-sm"
              style="margin-top: 150px;margin-bottom: 150px;"></span>
          </div>

          <div style="padding-top: 150px;padding-bottom: 150px;" *ngIf="!Searching">

          </div>
          <div class="col-sm-12 mb-5 text-center no-product"
            *ngIf="Searching && !SearchProducts.length && !IsLoadingSearch">
            <h3>{{'search:search-sorry-search-title' | translate}} </h3>
            <p>{{'search:search-sorry-search-text' | translate}}</p>
            <a [routerLink]="['/' | localize]" class="btn btn-solid">{{'search:search-continue-shopping' |
              translate}}</a>

          </div>
        </div>
      </div>
    </li>
    <li class="onhover-dropdown mobile-account">
      <div *ngIf="!user"><a [routerLink]="['/login' | localize]"><img src="assets/images/icon/user.svg"
            [ngClass]="{'menu-icon-filter': sticky}" style="width: 18px;" class="img-fluid" alt="user"><i
            class="far fa-user" [ngClass]="{'responsive-color-white': !sticky && home && !IsSearching}"
            aria-hidden="true"></i></a></div>
      <div *ngIf="user"><a [routerLink]="['/dashboard' | localize]"><img src="assets/images/icon/user-alt.svg"
            [ngClass]="{'menu-icon-filter': sticky}" style="width: 18px;" class="img-fluid" alt="user"><i
            class="fas fa-user" [ngClass]="{'responsive-color-white': !sticky && home && !IsSearching}"
            aria-hidden="true"></i>
          <span style="
            font-weight: 500;
            color: black;
            margin-left: 5px;">{{'header:user-hello-title' | translate}} {{user.Firstname}}</span>
        </a>

      </div>
      <ul *ngIf="user" class="mobile-d-none onshow-account">
        <!--  <div style="display: -webkit-inline-box;margin-top: 20px;margin-bottom: 20px;">
           <h2
             style="margin-right: 10px;text-align: center;margin: 0;font-weight: 400;letter-spacing: .1em;font-size: 28px;line-height: 42px;">
             {{'header:user-login-hello-message' | translate}} {{user.Firstname}}</h2>
          
         </div> -->
         <div>
           <a class="btn btn btn-myaccount" style="font-weight: 500;" [routerLink]="['/dashboard' | localize]"
             routerLinkActive="router-link-active">{{'header:user-login-hello-button' | translate}}</a>
         </div>
         <a (click)="LogOut()"
         style="cursor: pointer;margin-top: 15px;display: block;color: black;font-size: 13px;font-weight: 500;background-color: transparent;border: none;">{{'header:user-logout-button'
         | translate}}</a>
       </ul>
    </li>
    <li class="onhover-div mobile-wishlist">
      <div>
        <a [routerLink]="['/wishlist' | localize]">
          <img *ngIf="!wishlist.length" src="assets/images/icon/wishlist.svg" [ngClass]="{'menu-icon-filter': sticky}"
            style="width: 18px;" class="img-fluid" alt="">
          <img *ngIf="wishlist.length" src="assets/images/icon/wishlistfull.svg"
            [ngClass]="{'menu-icon-filter': sticky}" style="width: 18px;" class="img-fluid" alt="">
          <i [ngClass]="{'responsive-color-white': !sticky && home && !IsSearching}" class="fa fa-heart"
            aria-hidden="true"></i>
        </a>
      </div>
      <!-- <ul class="show-div shopping-cart" *ngIf='!wishlist.length'>
        <h5 style="font-weight: 500;">{{'header:wishlist-no-items-title' | translate}}</h5>
        <br />
        <p>{{'header:wishlist-no-items-message' | translate}}</p>
      </ul>
      <ul class="show-div shopping-cart" *ngIf='wishlist.length'>
        <li *ngFor="let product of wishlist">
          <div class="media">
            <a
              [routerLink]="[routerurl+ product?.URL + '-'  + product?.StockVariants[0].VariantCode]" >
              <img class="mr-3" [src]="product?.StockVariants[0].ImageLocation" [alt]="product?.StockVariants[0].alt">

            </a>
            <div class="media-body">
              <a
                [routerLink]="[routerurl + product?.URL  + product?.StockVariants[0].VariantCode]">
                <h4>{{ product?.Name }}</h4>
              </a>
              <h4>
                <span>
                  {{ product?.quantity }}
                  {{ product?.CardStoreSalesPrice * productService?.Currency.price |
                  currency:productService?.Currency.currency:'symbol' }}
                </span>
              </h4>
            </div>
          </div>
          <div class="close-circle" (click)="removeWishlistItem(product)">
            <a href="javascript:void(0)"><i class="fa fa-times" aria-hidden="true"></i></a>
          </div>
        </li>
      </ul> -->
    </li>
    <li class="onhover-div mobile-cart" *ngIf="CanSeePrice">
      <div>
        <a [routerLink]="['/cart' | localize]">
          <img src="assets/images/icon/shopping-bag.svg" [ngClass]="{'menu-icon-filter': sticky}" class="img-fluid"
            style="width: 18px;" alt=""><i class="ti-shopping-cart"
            [ngClass]="{'responsive-color-white': !sticky && home && !IsSearching}"></i>
        </a>
      </div>

      <span class="cart_qty_cls" *ngIf="getAmount">{{ getAmount | async}}</span>
      <!-- <ul class="show-div shopping-cart" *ngIf='!products.length'>
        <h5>Your cart is currently empty.</h5>
      </ul>
      <ul class="show-div shopping-cart" *ngIf='products.length'>
        <li *ngFor="let product of products">
          <div class="media">
            <a [routerLink]="['/wholesale-asyamakina-'+product.title.split(' ').join('-') + '-' + product.model + '-'  + product.variants[0].variant_code]">
              <img class="mr-3" [src]="product?.images[0].src" [alt]="product?.images[0].alt">
            </a>
            <div class="media-body">
              <a [routerLink]="['/wholesale-asyamakina-'+product.title.split(' ').join('-') + '-' + product.model + '-'  + product.variants[0].variant_code]">
                <h4>{{ product?.title }}</h4>
              </a>
              <h4>
                <span>
                  {{ product?.quantity }} x
                  {{ product?.price * productService?.Currency.price | currency:productService?.Currency.currency:'symbol' }}
                </span>
              </h4>
            </div>
          </div>
          <div class="close-circle" (click)="removeItem(product)">
            <a href="javascript:void(0)"><i class="fa fa-times" aria-hidden="true"></i></a>
          </div>
        </li>
        <li>
          <div class="total">
            <h5>subtotal : <span>{{ getTotal | async | currency:productService?.Currency.currency:'symbol' }}</span>
            </h5>
          </div>
        </li>
        <li>
          <div class="buttons">
            <a [routerLink]="['/cart' | localize]" class="view-cart">view cart</a>
            <a [routerLink]="['/checkout']" class="checkout">checkout</a>
          </div>
        </li>
      </ul> -->
    </li>
    <!-- <li class="onhover-dropdown mobile-language">
      <a href="javascript:void(0)" class="txt-dark">
        <h6 style="color: black;text-transform: uppercase;font-weight: 500;"><i
            class="flag-icon flag-icon-{{currentLanguage.flag}}" style="margin-right: 5px;"></i>{{currentLanguage.code}}
        </h6>
      </a>
       <ul class="language-dropdown onhover-show-div onhover-divs p-20">
        <li *ngFor="let language of languages" style="display: block;margin-bottom: 10px;"><a href="javascript:void(0)"
            style="color: #222341;font-weight: 500;" (click)="switchLang('tr')" data-lng="language.code"><i
              class="flag-icon flag-icon-{{language.flag}}"></i> {{language.name}}</a></li>
      </ul> 
    </li> -->
  </ul>
</div>