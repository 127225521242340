<ng-template class="theme-modal" #quickView let-modal>
    <div class="modal-content quick-view-modal">
        <div class="modal-body">
            <div style="text-align: right;">
                <button type="button" class="close" style="float: none;" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="row" [@fadeInOut] *ngIf="product">
                <div class="col-lg-6 col-xs-12">
                    <div class="row">
                        <div class="col-lg-2">
                            <div class="row">
                                <div *ngFor="let image of product.ProductVariant.Images; index as i">
                                    <div class="col-lg-12" style="padding-bottom: 5px;">
                                        <div class="owl-thumb" [class.active]="i == activeSlide"
                                            (click)="OpenImage(image.ImageRef)">
                                            <img [defaultImage]="'assets/images/product/placeholder.jpg'" *ngIf="image"
                                                style="border: 1px solid #f7f7f8;border-radius: 3px;"
                                                [lazyLoad]="image.ImageLocation" [alt]="image.alt"
                                                class="img-fluid gallery-image" [src]="image.ImageLocation"
                                                (click)="activeSlide = i.toString()">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-10">
                            <div class="lable-block" style="position: absolute;top: 10px;left: 25px;z-index: 1;">

                                <span class="new-product" *ngIf="product.IsNewProduct">{{'product:new-title' |
                                    translate}}</span>
                                <span class="no-stock" *ngIf="product.ProductVariant.Balance === 0">{{'product:no-stock'
                                    |
                                    translate}}</span>
                                <span class="sale"
                                    *ngIf="product.ProductVariant.Balance != 0 && product?.CardStoreSalesPrice != product?.OldSalesPrice && product?.CardStoreSalesPrice < product?.OldSalesPrice">
                                    {{'product:product-sale-text' | translate}} </span>
                                <span
                                    *ngIf="product.ProductVariant.Balance != 0 && product?.CardStoreSalesPrice != product?.OldSalesPrice && product?.CardStoreSalesPrice < product?.OldSalesPrice"
                                    class="sale-percent">
                                    <img alt="" src="assets/images/icon/two-down-arrows.svg"
                                        style="width: 12px;;margin-right: 5px;filter: invert(1);">{{
                                    ((product?.OldSalesPrice /
                                    product?.CardStoreSalesPrice )*100) - 100 | number: '1.0-0' }}%</span>
                            </div>
                            <span class="wishlist">
                                <a href="javascript:void(0)" *ngIf="!Wishlist()"
                                    style="vertical-align: middle;color: black;"
                                    title="{{'wishlist:wishlist-add-to-wishlist' | translate}}"
                                    (click)="addToWishlist(product)">
                                    <img src="assets/images/nullwishlist.svg" style="width: 20px;height: 20px;">
                                </a>
                                <a href="javascript:void(0)" *ngIf="Wishlist()" style="color: black;"
                                    title="Add to Wishlist" (click)="removeToWishlist(product)">
                                    <img src="assets/images/wishlist.svg" style="width: 20px;height: 20px;">
                                </a>

                            </span>
                            <div class="quick-view-img">
                                <img style="border-radius: 3px;"
                                    [src]="ImageSrc ? ImageSrc : product.ProductVariant.Images[0].ImageLocation"
                                    class="img-fluid quick-view-imgs">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 rtl-text">
                    <div class="product-right">
                        <h2
                            style="letter-spacing: 0;font-size: 17px;font-weight: 400;line-height: 18px;margin-top: 10px;">
                            {{
                            product?.Name }}</h2>

                        <h3 *ngIf="tokenService.getUser() && CanSeePrice">
                            <span style="display: block;margin-bottom: 15px;">
                                <ng-container *ngIf="product.SalesPriceCurr == 'TL'">
                                    {{ product?.CardStoreSalesPrice | currency: '
                                    ':'symbol':'.2-2':'it' }} ₺
                                </ng-container>
                                <ng-container *ngIf="product.SalesPriceCurr == 'EUR'">
                                    {{ product?.CardStoreSalesPrice * product?.CurrRate | currency: '
                                    ':'symbol':'.2-2':'it' }} ₺
                                </ng-container>
                            </span>


                            <span style="display: block;">
                                <ng-container *ngIf="product.SalesPriceCurr == 'TL'">
                                    {{ product?.CardStoreSalesPrice / product?.CurrRate | currency: '
                                    ':'symbol':'.2-4':'it' }} €
                                </ng-container>
                                <ng-container *ngIf="product.SalesPriceCurr == 'EUR'">
                                    {{ product?.CardStoreSalesPrice | currency: ' ':'symbol':'.2-4':'it' }} €
                                </ng-container>
                            </span>


                        </h3>
                        <h4 *ngIf="tokenService.getUser() && !CanSeePrice" style="color: #A83151;">
                            {{'product:product-no-permission' | translate}} </h4>
                        <a *ngIf="!tokenService.getUser()" class="only-member" [routerLink]="['/login' | localize]">
                            {{'product:product-only-member' | translate}}
                        </a>
                        <!-- <div>
                            <ul class="color-variant" id="color2-variant{{product?.Code}}"
                                *ngIf="Color(product?.StockVariants).length">
                                <li *ngFor="let color of Color(product?.StockVariants);let first = first;"
                                    [ngClass]="{'color-variant-active': color.ColorCode == product.ProductVariant.ColorCode}">
                                    <a class="color-variant-color"
                                        (click)="ChangeVariants(color.ColorName, product,$event,product?.Code)"
                                        [ngStyle]="{'background-color': color.HexCode}">
                                        <button class="color-variant-button">{{color.ColorName}}</button>
                                    </a>
                                </li>
                            </ul>


                        </div> -->

                        <div class="product-description border-product">
                            <!-- <h4 class="product-title size-text"
                                style="font-weight: 600;color: black;letter-spacing: 1px;font-size: 14px;"
                                *ngIf="Size(product.ItemSizes).length">
                                {{'product:set-quantity-text' | translate }} <span
                                    style="font-weight: 300; display: -webkit-inline-box;">{{product?.SetCount}}</span>
                            </h4>
                            <div class="size-box" *ngIf="Size(product.ItemSizes).length">
                                <ul>
                                    <li *ngFor="let size of Size(product.ItemSizes)">
                                        <a style="font-size: 14px;">{{ size.SizeName}}</a>
                                    </li>
                                </ul>
                                <ul style="margin-top:2px;">
                                    <li style="background-color: #f7f7f7;" *ngFor="let size of Size(product.ItemSizes)">
                                        <a style="font-weight: 300;">{{size.SizeAmount}}</a>
                                    </li>

                                    <span
                                        style="float: right;font-size: 13px;font-weight: 400;margin-top: 15px;font-size: 13px;color: #222222;">{{'product:size-weight-text'
                                        | translate }} : {{product?.SetWeight}} gr</span>
                                </ul>
                            </div> -->
                            <!-- <h5 class="avalibility" *ngIf="product?.SetCount <= product.ProductVariant.Balance"><span>
                                    {{'product:in-stock-text' |
                                    translate }}</span></h5> -->
                            <h5 class="avalibility" *ngIf="product?.SetCount > product.ProductVariant.Balance"><span>
                                    {{'product:out-of-stock-text'
                                    | translate }}</span></h5>
                            <h6 class="product-title" style="margin-top: 30px;font-weight: 600;">
                                {{'product:quantity-text' |
                                translate }}
                            </h6>
                            <div class="qty-box">
                                <div class="input-group">
                                    <span class="input-group-prepend">


                                        <button type="button" *ngIf="product.ProductVariant.Balance == 0"
                                            class="btn quantity-left-minus" data-type="minus"
                                            [disabled]="MinPurchaseSetAmountForSoldOut * product?.SetCount === counter "
                                            (click)="decrement(product)">
                                            <i class="ti-angle-left"></i>
                                        </button>
                                        <button type="button" *ngIf="product.ProductVariant.Balance != 0"
                                            class="btn quantity-left-minus" data-type="minus" [disabled]="counter == 0"
                                            (click)="decrement(product)">
                                            <i class="ti-angle-left"></i>
                                        </button>
                                    </span>
                                    <input type="number" name="quantity" class="form-control input-number"
                                        [value]="counter" (change)="quantityOnChange()" [(ngModel)]="counter" />
                                    <span class="input-group-prepend">
                                        <button type="button" *ngIf="product.ProductVariant.Balance == 0"
                                            class="btn quantity-right-plus" data-type="plus"
                                            (click)="increment(product)">
                                            <i class="ti-angle-right"></i>
                                        </button>
                                        <button type="button" *ngIf="product.ProductVariant.Balance != 0"
                                            class="btn quantity-right-plus" data-type="plus"
                                            [disabled]="product.ProductVariant.Balance < counter + product?.SetCount"
                                            (click)="increment(product)">
                                            <i class="ti-angle-right"></i>
                                        </button>
                                    </span>
                                </div>
                            </div>
                            <!-- <span
                                style="float: right;font-size: 14px;font-weight: 400;margin-top: -16px;font-size: 13px;color: #222222;">{{'product:balance-text'
                                | translate }} : {{product.ProductVariant.Balance}}</span> -->
                        </div>
                        <div class="product-buttons">
                            <a *ngIf="tokenService.getUser()" class="btn btn-addtocart"
                                style="font-size: 16px;font-weight: 500;"
                                [class.disabled]="tokenService.getUser() && !CanSeePrice || (counter > product.ProductVariant.Balance && product.ProductVariant.Balance != 0) || counter == 0"
                                (click)="addToCart(product)">{{'product:add-to-cart-button' | translate }}</a>
                            <a *ngIf="!tokenService.getUser()" class="btn btn-addtocart"
                                style="font-size: 16px;font-weight: 500;"
                                [routerLink]="['/login' | localize]">{{'product:add-to-cart-unregister-button' |
                                translate }}</a>

                        </div>
                        <div class="border-product">
                            <div (click)="OpenDetailText()" class="collapse-title">
                                <h6 class="product-title" style="font-weight: 500;font-size: 14px;">
                                    {{'product:details-text' | translate}}</h6>
                                <img src="assets/images/icon/plus.svg"
                                    style="width: 9px;position: absolute;right: 10px;" *ngIf="!detailtext" alt="">
                                <img src="assets/images/icon/remove.svg"
                                    style="width: 9px;position: absolute;right: 10px;" *ngIf="detailtext" alt="">
                            </div>
                            <div class="row" [@slideIn] *ngIf="detailtext" style="padding: 10px 0;">
                                <div class="col-lg-6" *ngIf="product?.OtherProp18">
                                    <p>- {{product?.OtherProp18}}</p>
                                </div>
                                <div class="col-lg-6" *ngIf="product?.ArmType">
                                    <p>- {{product?.ArmType}}</p>
                                </div>
                                <div class="col-lg-6" *ngIf="product?.CategoryName">
                                    <p>- {{product?.CategoryName}}</p>
                                </div>
                                <div class="col-lg-6" *ngIf="product?.OtherProp9">
                                    <p>- {{product?.OtherProp9}}</p>
                                </div>
                                <div class="col-lg-6" *ngIf="product?.OtherProp10">
                                    <p>- {{product?.OtherProp10}}</p>
                                </div>
                                <div class="col-lg-6" *ngIf="product?.Fabric">
                                    <p>- {{product?.Fabric}}</p>
                                </div>
                                <div class="col-lg-6" *ngIf="product?.OtherProp11">
                                    <p>- {{product?.OtherProp11}}</p>
                                </div>
                                <div class="col-lg-6" *ngIf="product?.OtherProp12">
                                    <p>- {{product?.OtherProp12}}</p>
                                </div>
                                <div class="col-lg-6" *ngIf="product?.ClassType">
                                    <p>- {{product?.ClassType}}</p>
                                </div>
                                <div class="col-lg-6" *ngIf="product?.OtherProp13">
                                    <p>- {{product?.OtherProp13}}</p>
                                </div>
                                <div class="col-lg-6" *ngIf="product?.OtherProp14">
                                    <p>- {{product?.OtherProp14}}</p>
                                </div>
                                <div class="col-lg-6" *ngIf="product?.MarkName">
                                    <p>- {{product?.MarkName}}</p>
                                </div>
                                <div class="col-lg-6" *ngIf="product?.OtherProp21">
                                    <p>- {{product?.OtherProp21}}</p>
                                </div>
                                <div class="col-lg-6" *ngIf="product?.OtherProp15">
                                    <p>- {{product?.OtherProp15}}</p>
                                </div>
                                <div class="col-lg-6" *ngIf="product?.OtherProp16">
                                    <p>- {{product?.OtherProp16}}</p>
                                </div>
                                <div class="col-lg-6" *ngIf="product?.OtherProp17">
                                    <p>- {{product?.OtherProp17}}</p>
                                </div>
                                <div class="col-lg-6" *ngIf="product?.OtherProp19">
                                    <p>- {{product?.OtherProp19}}</p>
                                </div>
                                <div class="col-lg-6" *ngIf="product?.OtherProp20">
                                    <p>- {{product?.OtherProp20}}</p>
                                </div>
                                <div class="col-lg-12" *ngIf="product?.WashInfo">
                                    <p>- {{product?.WashInfo}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="border-product" *ngIf="product?.WEBDescription">
                            <div (click)="OpenDescriptionText()" class="collapse-title">
                                <h6 class="product-title" style="font-weight: 500;font-size: 14px;">
                                    {{'product:description-text' | translate}}
                                </h6>
                                <img src="assets/images/icon/plus.svg"
                                    style="width: 9px;position: absolute;right: 10px;" *ngIf="!descriptiontext" alt="">
                                <img src="assets/images/icon/remove.svg"
                                    style="width: 9px;position: absolute;right: 10px;" *ngIf="descriptiontext" alt="">
                            </div>
                            <div [@slideIn] *ngIf="descriptiontext" style="padding: 10px 0;text-align: justify;">
                                {{ product?.WEBDescription }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" *ngIf="!product">
                <div class="col-sm-12" style="padding:250px 0 ;">
                    <span class="spinner-border spinner-border-sm ml-1" style="left: 50%;position: relative;"></span>
                </div>
            </div>
        </div>
    </div>
</ng-template>