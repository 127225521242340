<!-- header start -->
<header id="header" [ngClass]="{'sticky': home && !stick ,'fixed': stick && sticky || IsSearching, 'backgroundwhite' : backgroundwhite || IsSearching,'home-fixed': stick && sticky && home || IsSearching}"
    (mouseover)="backgroundwhite=true" (mouseout)="backgroundwhite=false">
   
    <!-- <div class="top-header top-header-dark2" *ngIf="topbar">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="header-contact">
                        <ul>
                            <li>Welcome to Our store ASYAMAKİNA</li>
                            <li><i class="fa fa-phone" aria-hidden="true"></i>Call Us: 123 - 456 - 7890</li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6 text-right">
                    <ul class="header-dropdown">
                        <li class="compare">
                            <a [routerLink]="['/shop/compare']" style="color: white;"><i class="fa fa-random"
                                    aria-hidden="true"></i>Compare</a>
                        </li>
                        <li class="mobile-wishlist">
                            <a [routerLink]="['/shop/wishlist']" style="color: white;"><i class="fa fa-heart"
                                    aria-hidden="true"></i>Wishlist</a>
                        </li>
                        <li class="onhover-dropdown mobile-setting" style="margin-right: 10px;">
                            <i class="ti-world" style="font-size: 16px;"></i>
                            <ul class="onhover-show-div">
                                <li *ngFor="let language of languages">
                                    <a href="javascript:void(0)" style="color: black!important;"
                                        (click)="changeLanguage(language.code)">{{ language.name }}</a>
                                </li>
                            </ul>
                            
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div> -->

    <div class="container layout3-menu">
        <div class="row">
            <div class="col-lg-12 padding-50" [ngClass]="{'border-bottom-responsive': stick}" >
                <div class="main-menu">
                    <div class="menu-left around-border">
                        <div class="main-menu-right">
                            <app-menu [stick]="stick" [home]="home" [IsSearching]="IsSearching"></app-menu>
                        </div>
                    </div>
                    <div class="absolute-logo">
                        <div class="brand-logo">
                            <a [href]="['/' | localize]">
                                <img class="display-logo" style="vertical-align: baseline;" [src]="themeLogo" alt="logo">
                                <img *ngIf="!stick && home && !IsSearching" class="responsive-logo" style="vertical-align: baseline;" [src]="responsivethemeLogo" alt="logo">
                                <img *ngIf="stick || !home || IsSearching" class="responsive-logo" style="vertical-align: baseline;" [src]="responsivethemeLogodark" alt="logo">
                            </a>
                        </div>
                    </div>
                    <div class="">
                        <div class="menu-right pull-right">
                            <div>
                                <app-settings [sticky]="stick" (myevent)="click($event)" [home]="home"></app-settings>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
<!-- header end -->