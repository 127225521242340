import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Location }             from '@angular/common';
import { LocalizeParser, LocalizeRouterModule, LocalizeRouterSettings, ManualParserLoader } from '@gilsdav/ngx-translate-router';
import { TranslateService } from '@ngx-translate/core';

export function createTranslateLoader(translate: TranslateService, location: Location, settings: LocalizeRouterSettings) {
  return new ManualParserLoader(translate, location, settings, ['tr'], 'ROUTES.');
}

export const routes: Routes = [
  { path: '', redirectTo: '/osram-tum-urunler', pathMatch: 'full' },
  { path: '**', redirectTo: '/osram-tum-urunler' },
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    data: {
      discriminantPathKey: 'HOMEPATH'
    }
  },
  {
    path: '',
    loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule),
    data: {
      discriminantPathKey: 'SHOPPATH'
    }
  },
  {
    path: '',
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
    data: {
      discriminantPathKey: 'PAGESPATH'
    }
  },
];

@NgModule({

  imports: [
    RouterModule.forRoot(routes, { initialNavigation: 'disabled', relativeLinkResolution: 'legacy' }),
    LocalizeRouterModule.forRoot(routes, {
      parser: {
        provide: LocalizeParser,
        useFactory: (createTranslateLoader),
        deps: [TranslateService, Location, LocalizeRouterSettings/*, HttpClient*/]
      },
      initialNavigation: true
    })
  ],
  exports: [ RouterModule, LocalizeRouterModule]
})
export class AppRoutingModule { }
