import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

declare var $: any;
@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

  @Input() category;
  public collapse: boolean = true;
  propsList;
  props: Array<any> = [];
  @Input() paramsprops;
  @Input() RemoveAllTags;

  @Output() PropsFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() SendPropsList: EventEmitter<any> = new EventEmitter<any>();
  @Input() categories: any = [];
  constructor() {
  }

  ngOnInit(): void {

    if (this.categories.length) {
      this.categories.forEach(element => {
        if (element.CategoryRef == this.category) {
          this.propsList = element.Props;
          this.SendPropsList.emit(this.propsList);
          if (this.paramsprops != undefined) {
            const keyNames = Object.getOwnPropertyNames(this.paramsprops);

            keyNames.forEach(element => {

              if (element != 'Category') {

                this.propsList.forEach(item => {
                  let propname = keyNames.find(x => x == item.PropNameAlias.replace(/ /g, '-'));
                  if (propname) {
                    if (this.props.length) {
                      let index = this.props.indexOf(this.props.find(x => x.PropName === propname));
                      if (index === -1) {
                        let val = this.paramsprops[propname];
                        this.props.push({ PropName: propname, prop: val.split(',') });
                      }
                    }
                    else {
                      let val = this.paramsprops[propname];
                      this.props.push({ PropName: propname, prop: val.split(',') });
                    }
                  }
                });
              }
              else {
                if (!this.props.length) {
                  let val = this.paramsprops['Category'];
                  this.props.push({ PropName: 'Category', prop: val.split(',') });
                }
              }
            });
          }
        }
      });
    }
    // else {
    //   this.categories.forEach(element => {
    //     if (element.CategoryRef == this.category) {
    //       this.propsList = element.Props;
    //       this.SendPropsList.emit(this.propsList);
    //       if (this.paramsprops != undefined) {
    //         const keyNames = Object.getOwnPropertyNames(this.paramsprops);
    //         keyNames.forEach(element => {
    //           if (element != 'Category') {
    //             this.propsList.forEach(item => {
    //               let propname = keyNames.find(x => x == item.PropNameAlias.replace(/ /g, '-'));
    //               if (propname) {
    //                 if (this.props.length) {
    //                   let index = this.props.indexOf(this.props.find(x => x.PropName === propname));
    //                   if (index === -1) {
    //                     let val = this.paramsprops[propname];
    //                     this.props.push({ PropName: propname, prop: val.split(',') });
    //                   }
    //                 }
    //                 else {
    //                   let val = this.paramsprops[propname];
    //                   this.props.push({ PropName: propname, prop: val.split(',') });
    //                 }
    //               }
    //             });
    //           }
    //           else {
    //             if (!this.props.length) {
    //               let val = this.paramsprops['Category'];
    //               this.props.push({ PropName: 'Category', prop: val.split(',') });
    //             }
    //           }
    //         });
    //       }
    //     }
    //   });
    // }
  }


  OpenCollapse(id) {
    if ($('#OpenCollapse' + id).hasClass("open")) {
      $('#OpenCollapse' + id).removeClass('open');
    }
    else {
      $('#OpenCollapse' + id).addClass('open');
    }
  }

  appliedFilter(event, proptype) {
    proptype = proptype.replace(/ /g, '-');
    if (event.target.checked) {
      let index;

      if (this.props.length) {

        index = this.props.indexOf(this.props.find(x => x.PropName == proptype));
        if (index === -1) {
          this.props.push({ PropName: proptype, prop: [event.target.value.replace(/ /g, '-')] }); // push in array cheked value
        }
        else {
          this.props[index].prop.push(event.target.value.replace(/ /g, '-'));
        }
      }
      else {
        index = -1
        this.props.push({ PropName: proptype, prop: [event.target.value.replace(/ /g, '-')] }); // push in array cheked value
      }

    }
    else {

      let index = this.props.indexOf(this.props.find(x => x.PropName == proptype));
      let index2 = this.props[index].prop.indexOf(event.target.value.replace(/ /g, '-'));
      if (index2 != -1) this.props[index].prop.splice(index2, 1);
      if (!this.props[index].prop.length) {
        this.props.splice(index, 1);  // removed in array unchecked value 
      }
    }

    let props = this.props.length ? this.props.find(x => x.PropName == proptype) ? { [proptype]: this.props.find(x => x.PropName == proptype).prop.join(",").replace(/ /g, '-') } : { [proptype]: null } : { [proptype]: null };

    this.PropsFilter.emit(props);
  }

  // check if the item are selected
  checked(item) {
    if (this.props.length) {
      if (this.props.indexOf(this.props.find(x => x.prop.find(items => items === item.replace(/ /g, '-')))) != -1) {
        return true;
      }
    }
  }
}