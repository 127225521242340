import { Component, OnInit, Input } from '@angular/core';
import { NewProductSlider } from '../../../data/slider';
import { Product } from '../../../classes/product';
import { ProductService } from '../../../services/product.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { TokenService } from 'src/app/shared/services/token.service';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it'
registerLocaleData(localeIt, 'it');

@Component({
  selector: 'app-product-box-vertical-slider',
  templateUrl: './product-box-vertical-slider.component.html',
  styleUrls: ['./product-box-vertical-slider.component.scss']
})
export class ProductBoxVerticalSliderComponent implements OnInit {

  @Input() title: string = 'New Product'; // Default
  @Input() type: string = 'fashion'; // Default Fashion
  @Input() collection: boolean = false;
  routerurl;

  @Input() products: Product[] = [];

  public NewProductSliderConfig: any = NewProductSlider;
  CanSeePrice;

  constructor(public productService: ProductService, private translate: TranslateService,public tokenService: TokenService,) {
    if(this.tokenService.getUser()) this.CanSeePrice = this.tokenService.getUser().CanSeePrice == 'False' ? false : true;
  }

  ngOnInit(): void {
    if (this.collection) {
      this.routerurl = "..";
    }
    else {
      this.routerurl = "";
    }
  }

}
