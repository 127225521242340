<!-- side-bar single product slider start -->
<div class="theme-card">
    <h5 class="title-border">{{ title }}</h5>
    <owl-carousel-o [options]="NewProductSliderConfig" class="offer-slider">
        <ng-container *ngFor="let product of products | slice:0:3 ">  
            <ng-template carouselSlide>
                <div>
                  <div class="media" *ngFor="let product of products | slice:0:3 ">
                    <a [routerLink]="[routerurl+ product?.URL + '-'  + product.StockVariants[0].VariantCode | localize]" *ngIf="product.StockVariants[0].Images.length">
                      <img class="img-fluid w-auto"
                           [defaultImage]="'assets/images/product/placeholder.jpg'" 
                           [lazyLoad]="product.StockVariants[0].Images[0].ImageLocation" alt="">
                    </a>
                    <div class="media-body align-self-center">
                      <a [routerLink]="[routerurl+product?.URL +'-'  + product.StockVariants[0].VariantCode | localize]">
                      <h6>{{ product.Name }}</h6></a>
                      <ng-container *ngIf="product.StockVariants[0].Balance === 0">
                        <h4 *ngIf="tokenService.getUser() && CanSeePrice">{{ product?.OldSalesPrice > product?.CardStoreSalesPrice ? (product?.OldSalesPrice | currency: ' ':'symbol':'.2-4':'it') : product?.CardStoreSalesPrice | currency: ' ':'symbol':'.2-4':'it' }}</h4>
                        <h4 *ngIf="tokenService.getUser() && !CanSeePrice" style="color: #A83151;"> {{'product:product-no-permission' | translate}} </h4>
                      </ng-container>
                      <ng-container *ngIf="product.StockVariants[0].Balance !== 0">
                        <h4
                          *ngIf="tokenService.getUser() && CanSeePrice && product?.CardStoreSalesPrice == product?.OldSalesPrice || product?.CardStoreSalesPrice > product?.OldSalesPrice">
                         {{ product?.CardStoreSalesPrice | currency: ' ':'symbol':'.2-4':'it' }}</h4>
                        <h4
                          *ngIf="tokenService.getUser() && CanSeePrice && product?.CardStoreSalesPrice != product?.OldSalesPrice && product?.CardStoreSalesPrice < product?.OldSalesPrice"
                          style="color: #A83151;font-size: 16px;">
                          <del style="font-size: 14px;color: #aaa;"><span class="money">{{ product?.OldSalesPrice | currency: '
                              ':'symbol':'.2-4':'it' }}</span></del>
                         {{ product?.CardStoreSalesPrice | currency: ' ':'symbol':'.2-4':'it' }} (-{{ ((product?.OldSalesPrice /
                          product?.CardStoreSalesPrice )*100) - 100 | number: '1.0-0' }}%)
                        </h4>
                        <h4 *ngIf="tokenService.getUser() && !CanSeePrice" style="color: #A83151;"> {{'product:product-no-permission' | translate}} </h4>
                      </ng-container>
                      <a *ngIf="!tokenService.getUser()" class="only-member" [routerLink]="[routerurl+product?.URL +'-'  + product.StockVariants[0].VariantCode | localize]" >
                        {{'product:product-only-member' | translate}}
                      </a> 
                    </div>
                  </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div>
                  <div class="media" *ngFor="let product of products | slice:3:6 ">
                    <a [routerLink]="[routerurl+product?.URL + '-'  + product.StockVariants[0].VariantCode | localize]" *ngIf="product.StockVariants[0].Images.length">
                      <img class="img-fluid w-auto" 
                           [defaultImage]="'assets/images/product/placeholder.jpg'" 
                           [lazyLoad]="product.StockVariants[0].Images[0].ImageLocation" alt="">
                    </a>
                    <div class="media-body align-self-center">
                      <a [routerLink]="[routerurl+product?.URL + '-'  + product.StockVariants[0].VariantCode | localize]">
                      <h6>{{ product.Name }}</h6></a>
                      <ng-container *ngIf="product.StockVariants[0].Balance === 0">
                        <h4 *ngIf="tokenService.getUser() && CanSeePrice">{{ product?.CardStoreSalesPrice | currency: ' ':'symbol':'.2-4':'it' }} {{product?.SalesPriceCurr}}</h4>
                        <h4 *ngIf="tokenService.getUser() && !CanSeePrice" style="color: #A83151;"> {{'product:product-no-permission' | translate}} </h4>
                      </ng-container>
                      <ng-container *ngIf="product.StockVariants[0].Balance !== 0">
                        <h4
                          *ngIf="tokenService.getUser() && CanSeePrice && product?.CardStoreSalesPrice == product?.OldSalesPrice || product?.CardStoreSalesPrice > product?.OldSalesPrice">
                         {{ product?.CardStoreSalesPrice | currency: ' ':'symbol':'.2-4':'it' }} {{product?.SalesPriceCurr}}</h4>
                        <h4
                          *ngIf="tokenService.getUser() && CanSeePrice && product?.CardStoreSalesPrice != product?.OldSalesPrice && product?.CardStoreSalesPrice < product?.OldSalesPrice"
                          style="color: #A83151;font-size: 16px;">
                          <del style="font-size: 14px;color: #aaa;"><span class="money">{{ product?.OldSalesPrice | currency: '
                              ':'symbol':'.2-4':'it' }}</span></del>
                         {{ product?.CardStoreSalesPrice | currency: ' ':'symbol':'.2-4':'it' }} (-{{ ((product?.OldSalesPrice /
                          product?.CardStoreSalesPrice )*100) - 100 | number: '1.0-0' }}%)
                        </h4>
                        <h4 *ngIf="tokenService.getUser() && !CanSeePrice" style="color: #A83151;"> {{'product:product-no-permission' | translate}} </h4>
                      </ng-container>
                      <a *ngIf="!tokenService.getUser()" class="only-member" [routerLink]="[routerurl+product?.URL +'-'  + product.StockVariants[0].VariantCode | localize]" >
                        {{'product:product-only-member' | translate}}
                      </a> 
                    </div>
                  </div>
                </div>
            </ng-template>
        </ng-container>
    </owl-carousel-o>
</div>
<!-- side-bar single product slider end -->
  