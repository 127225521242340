import { BrowserModule } from '@angular/platform-browser';
import { APP_ID, Inject, NgModule, PLATFORM_ID } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { ToastrModule } from 'ngx-toastr';

import { DatePipe, HashLocationStrategy, isPlatformBrowser, Location, LocationStrategy } from '@angular/common';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedModule } from './shared/shared.module';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { ShopComponent } from './shop/shop.component';
import { PagesComponent } from './pages/pages.component';

import { LocalizeRouterSettings } from '@gilsdav/ngx-translate-router';
import { LocalizeRouterHttpLoader } from '@gilsdav/ngx-translate-router-http-loader';
import { environment } from 'src/environments/environment';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


export function createTranslateLoader(http: HttpClient) {
  //return new TranslateHttpLoader(http, '/assets/locales/', '.json');
  return new TranslateHttpLoader(http, environment.url + "app/GetTranslateWebPanel?langCode=", "");
}
export function HttpLoaderFactory(translate: TranslateService, location: Location, settings: LocalizeRouterSettings, http: HttpClient) {
  return new LocalizeRouterHttpLoader(translate, location, { ...settings, alwaysSetPrefix: true }, http);
}

@NgModule({
  declarations: [
    AppComponent,
    ShopComponent,
    PagesComponent
  ],
  imports: [
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    LoadingBarHttpClientModule,
    AppRoutingModule,
    LoadingBarRouterModule,
    BrowserModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    BrowserModule,
    HttpClientModule,
    NgxIntlTelInputModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [/* PLATFORM_ID, */HttpClient]
      }
    }),
    // RouterModule.forRoot(routes, {
    //   initialNavigation: 'enabled',
    //   useHash: false,
    //   anchorScrolling: 'enabled',
    //   scrollPositionRestoration: 'enabled'
    // }),
    // LocalizeRouterModule.forRoot(routes, {
    //   parser: {
    //     provide: LocalizeParser,
    //     useFactory: HttpLoaderFactory,
    //     deps: [TranslateService, Location, LocalizeRouterSettings, HttpClient]
    //   },

    //   cacheMechanism: CacheMechanism.Cookie,
    //   cookieFormat: '{{value}};{{expires:20}};path=/',
    // }),
    SharedModule
  ],
  providers: [ DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(APP_ID) private appId: string) {
    const platform = isPlatformBrowser(platformId) ?
      'in the browser' : 'on the server';
    }
 }
