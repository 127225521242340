<app-breadcrumb [breadcrumb]="data.PageName"></app-breadcrumb>

<section class=" contact-page section-b-space padding-50" *ngIf="data.InnerHTML">
    <div class="row mb-4" *ngIf="WideBanner">
        <div class="col-md-12" *ngIf="WideBanner.URL && WideBanner.IsActive">
            <a [href]="WideBanner.URL | localize">
                <img style="width: 100%;" *ngIf="WideBanner.IsImage" [ngStyle]="{'border-radius': WideBanner.BorderRadius}" [alt]="WideBanner.Alt"
                    src="https://www.asyab2b.com/AdminService/{{WideBanner.ImageLocation}}" />
                <video *ngIf="WideBanner.IsVideo" [ngStyle]="{'border-radius': WideBanner.BorderRadius}" style="width: 100%;" loop muted autoplay
                    oncanplay="this.play()" onloadedmetadata="this.muted = true">
                    <source src="https://www.asyab2b.com/AdminService/{{WideBanner.ImageLocation}}"
                        type="video/mp4" />
                </video>
                <div
                    style="display: flex; flex-direction: column; align-items: stretch; position: absolute; top: 0px; left: 0px; width: 100%; height: 100%;">
                    <div class="footer-banner-big" [ngStyle]="{'margin': WideBanner.DivMargin}">
                        <div [style]="WideBanner.DivText">
                            <span class="banner-text" *ngIf="WideBanner.TextArea"
                                [innerHTML]="WideBanner.TextArea | sanitizeHtml"></span>
                        </div>
                    </div>
                    <div *ngIf="WideBanner.DivButton" [innerHTML]="WideBanner.DivButton | sanitizeHtml">

                    </div>
                </div>
            </a>
        </div>
        <div class="col-md-12" *ngIf="!WideBanner.URL && WideBanner.IsActive">
            <img style="width: 100%;" *ngIf="WideBanner.IsImage" [ngStyle]="{'border-radius': WideBanner.BorderRadius}" [alt]="WideBanner.Alt"
                    src="https://www.asyab2b.com/AdminService/{{WideBanner.ImageLocation}}" />
                <video *ngIf="WideBanner.IsVideo" [ngStyle]="{'border-radius': WideBanner.BorderRadius}" style="width: 100%;" loop muted autoplay
                    oncanplay="this.play()" onloadedmetadata="this.muted = true">
                    <source src="https://www.asyab2b.com/AdminService/{{WideBanner.ImageLocation}}"
                        type="video/mp4" />
                </video>
            <div
                style="display: flex; flex-direction: column; align-items: stretch; position: absolute; top: 0px; left: 0px; width: 100%; height: 100%;">
                <div class="footer-banner-big" [ngStyle]="{'margin': WideBanner.DivMargin}">
                    <div [style]="WideBanner.DivText">
                        <span class="banner-text" *ngIf="WideBanner.TextArea"
                            [innerHTML]="WideBanner.TextArea | sanitizeHtml"></span>
                    </div>
                </div>
                <div *ngIf="WideBanner.DivButton" [innerHTML]="WideBanner.DivButton | sanitizeHtml">

                </div>
            </div>
        </div>

    </div>
    <div [innerHTML]="data.InnerHTML | sanitizeHtml"></div>
</section>