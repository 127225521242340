import {
  Component, OnInit, OnDestroy, ViewChild, TemplateRef, Input,
  PLATFORM_ID, Inject, Renderer2
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ProductService } from '../../../../shared/services/product.service';
import { TokenService } from 'src/app/shared/services/token.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

declare var $: any;
@Component({
  selector: 'app-quick-view',
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(500, style({ opacity: 0 }))
      ])
    ]),
    trigger('slideIn', [
      state('*', style({ 'overflow-y': 'hidden' })),
      state('void', style({ 'overflow-y': 'hidden' })),
      transition('* => void', [
        style({ height: '*' }),
        animate(200, style({ height: 0 }))
      ]),
      transition('void => *', [
        style({ height: '0' }),
        animate(200, style({ height: '*' }))
      ])
    ])
  ],
  templateUrl: './quick-view.component.html',
  styleUrls: ['./quick-view.component.scss']
})
export class QuickViewComponent implements OnInit, OnDestroy {

  CanSeePrice: boolean = false;
  product: any = {};
  @Input() currency: any;
  public activeSlide: any = 0;
  products: any = [];
  descriptiontext = true;
  detailtext = true;
  @ViewChild("quickView", { static: false }) QuickView: TemplateRef<any>;

  public closeResult: string;
  public ImageSrc: string;
  public counter: number = 1;
  public modalOpen: boolean = false;
  MinPurchaseSetAmountForSoldOut;

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private http: HttpClient, public tokenService: TokenService, private toastrService: ToastrService, private render: Renderer2,
    private translate: TranslateService,
    private router: Router, private modalService: NgbModal,
    public productService: ProductService) { }

  ngOnInit(): void {
    if (this.tokenService.getUser()) this.CanSeePrice = this.tokenService.getUser().CanSeePrice == 'False' ? false : true;

  }

  openModal(product) {
    this.modalOpen = true;
    this.http.get<any>(environment.url + 'Products/GetProductByRef?Ref=' + product.StockRef + '&LangCode=' + this.translate.currentLang).subscribe(data => {
      this.product = data;
      this.product.ProductVariant = product.ProductVariant;
      this.product.ProductVariant.Images = this.product.ProductVariant.Images.filter(x => x.VariantRef === this.product.ProductVariant.VariantRef)

      if (isPlatformBrowser(this.platformId)) { // For SSR 
        this.modalService.open(this.QuickView, {
          size: 'xl',
          ariaLabelledBy: 'modal-basic-title',
          centered: true,
          scrollable: true,
          windowClass: 'modal-holder'
        }).result.then((result) => {
          `Result ${result}`
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      }
      this.http.get<any>(environment.url + 'FirmMultipler/GetFirmMultipler').subscribe(data => {
        this.MinPurchaseSetAmountForSoldOut = data.MinPurchaseSetAmountForSoldOut;
        let cartItem = undefined;
        if (localStorage['AsyaCartItems']) {
          cartItem = JSON.parse(localStorage['AsyaCartItems']).find(item => item.ProductVariant.Barcode === this.product.ProductVariant.Barcode);
        }
        this.counter = this.product.ProductVariant.Balance == 0 && cartItem == undefined ? this.product.SetCount * this.MinPurchaseSetAmountForSoldOut : this.product.SetCount;
      });

    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  // Get Product Color
  Color(variants) {
    const uniqColor = [];
    for (let i = 0; i < Object.keys(variants).length; i++) {
      if (uniqColor.indexOf(variants[i].ColorName) === -1 && variants[i].ColorName) {
        uniqColor.push(variants[i])
      }
    }
    return uniqColor
  }

  // Get Product Size
  Size(ItemSizes) {
    const uniqSize = [];
    for (let i = 0; i < Object.keys(ItemSizes).length; i++) {
      if (uniqSize.indexOf(ItemSizes[i]) === -1 && ItemSizes[i]) {
        uniqSize.push(ItemSizes[i])
      }
    }
    return uniqSize
  }

  OpenImage(ref) {
    this.ImageSrc = this.product.ProductVariant.Images.find(x => x.ImageRef == ref).ImageLocation;

  }
  // Change Variants
  ChangeVariants(color, product, event, id) {
    let item = product.StockVariants.find(x => x.ColorName == color);
    this.ImageSrc = item.Images[0].ImageLocation
    this.product.ProductVariant = item;
    let cartItem = undefined;
    if (localStorage['AsyaCartItems']) {
      cartItem = JSON.parse(localStorage['AsyaCartItems']).find(item => item.ProductVariant.Barcode === this.product.ProductVariant.Barcode);
    }
    this.counter = this.product.ProductVariant.Balance == 0 && cartItem == undefined ? this.product.SetCount * this.MinPurchaseSetAmountForSoldOut : this.product.SetCount;
    $('#color2-variant' + id + ' li').removeClass("color-variant-active");
    this.render.addClass(event.target.parentElement, "color-variant-active");
  }

  OpenDescriptionText() {
    if (this.descriptiontext) this.descriptiontext = false;
    else this.descriptiontext = true;
  }
  OpenDetailText() {
    if (this.detailtext) this.detailtext = false;
    else this.detailtext = true;
  }
  quantityOnChange() {
    if (this.counter < this.product.SetCount) {
      this.counter = this.product.SetCount;

    }
    else if (this.product.ProductVariant.Balance < this.counter) {
      this.counter = this.product.ProductVariant.Balance;

    }
    else {
      let count = this.counter % this.product.SetCount;
      if (count != 0) {
        this.counter = this.counter - count;
      }

    }

  }
  // Increament
  increment(product: any = {}) {
    if (product.ProductVariant.Balance == 0) {
      this.counter = this.counter + product.SetCount;
    }
    else {
      if (product.ProductVariant.Balance < this.counter + product.SetCount) {
        let translate = this.translate.translations[this.translate.currentLang];
        // this.toastrService.error(translate['notification-error:product-add-more-item'] + product.ProductVariant.Balance);
        this.toastrService.error(translate['notification-error:product-add-more-item']);
      }
      else {
        this.counter = this.counter + product.SetCount;
      }
    }
  }

  // Decrement
  decrement(product: any = {}) {
    if (this.counter > 1) {
      if (this.counter > product.SetCount) {
        this.counter = this.counter - product.SetCount;
      }
    }
  }

  // Add to cart
  async addToCart(product: any = {}) {
    product.CartAmount = this.counter || 1;
    product.stock = product.ProductVariant.Balance;
    product.Code = product.ProductVariant.Barcode;
    const status = await this.productService.addToCart(product);
    this.counter = product.SetCount;
    if (status) {
      let translate = this.translate.translations[this.translate.currentLang];
      this.toastrService.success(translate['notification:product-added-cart']);

    }
  }
  Wishlist() {
    if (this.tokenService.getUser()) {
      if (this.products.length <= 0) {
        this.products = this.productService.getwishlistItems;
        if (this.products.find(x => x.ProductVariant.Barcode === this.product.ProductVariant.Barcode)) {
          return true
        }
        else {
          return false
        }
      }
      else {
        if (this.products.find(x => x.ProductVariant.Barcode === this.product.ProductVariant.Barcode)) {
          return true
        }
        else {
          return false
        }
      }
    }
    else {
      return false
    }

  }
  addToWishlist(product: any) {
    product.CartAmount = this.product.SetCount;
    this.productService.addToWishlist(product);
    this.products = this.productService.getwishlistItems;
  }
  removeToWishlist(product: any) {
    this.productService.removeWishlistItem(product);
    this.products = this.productService.getwishlistItems;
  }
  ngOnDestroy() {
    if (this.modalOpen) {
      this.product = {};
      this.modalService.dismissAll();
    }
  }

}
