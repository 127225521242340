import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, Input, HostListener, PLATFORM_ID, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { Product } from '../../classes/product';
import { ProductService } from '../../services/product.service';

@Component({
  selector: 'app-header-two',
  templateUrl: './header-two.component.html',
  styleUrls: ['./header-two.component.scss']
})
export class HeaderTwoComponent implements OnInit {
  public products: Product[] = []

  IsSearching;
  @Input() home: boolean = false;
  @Input() themeLogo: string = 'assets/images/icon/logo.png'; // Default Logo
  @Input() responsivethemeLogo: string = 'assets/images/icon/responsive-logo.png'; // Default Logo
  @Input() responsivethemeLogodark: string = 'assets/images/icon/responsive-logo.png'; // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false
  @Input() stick: boolean = false; // Default false
  alerts = true;
  backgroundwhite: boolean = false;



  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private translate: TranslateService,
    public productService: ProductService) {

  }

  ngOnInit(): void {
  }

  get getTotal(): Observable<number> {
    return this.productService.cartTotalAmount();
  }

  removeItem(product: any) {
    this.productService.removeCartItem(product);
  }

  changeCurrency(currency: any) {
    this.productService.Currency = currency
  }
  click(data) {
    if (data.IsSearching) this.IsSearching = true;
    else this.IsSearching = false;
  }
  CloseAlert() {
    this.alerts = false;
  }
  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number >= 100 && window.innerWidth > 200) {
      this.stick = true;
    } else {
      this.stick = false;
    }
  }

}
