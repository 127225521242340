<div *ngIf="!loader">
  <!-- <div class="img-wrapper">
    <div class="lable-block" style="position: absolute;top: 10px;left: 8px;z-index: 1;">
      <span class="new-product" *ngIf="product.IsNewProduct">{{'product:new-title' | translate}}</span>
      <span class="no-stock" *ngIf="ProductVariantCode.Balance === 0">{{'product:no-stock' | translate}}</span>
      <span class="sale"
        *ngIf="ProductVariantCode.Balance != 0 && product?.CardStoreSalesPrice != product?.OldSalesPrice && product?.CardStoreSalesPrice < product?.OldSalesPrice">{{'product:product-sale-text'
        | translate}}</span>
      <span
        *ngIf="ProductVariantCode.Balance != 0 && product?.CardStoreSalesPrice != product?.OldSalesPrice && product?.CardStoreSalesPrice < product?.OldSalesPrice"
        class="sale-percent">
        <img alt="" src="assets/images/icon/two-down-arrows.svg"
          style="width: 12px;;margin-right: 5px;filter: invert(1);">{{ ((product?.OldSalesPrice /
        product?.CardStoreSalesPrice )*100) - 100 | number: '1.0-0' }}%</span>

    </div>
    <span class="wishlist">
      <a href="javascript:void(0)" *ngIf="!Wishlist()" style="vertical-align: middle;color: black;"
        title="{{'wishlist:wishlist-add-to-wishlist' | translate}}" (click)="addToWishlist(product)">
        <img src="assets/images/nullwishlist.svg" style="width: 20px;height: 20px;">
      </a>
      <a href="javascript:void(0)" *ngIf="Wishlist()" style="color: black;"
        title="{{'wishlist:wishlist-add-to-wishlist' | translate}}" (click)="removeToWishlist(product)">
        <img src="assets/images/wishlist.svg" style="width: 20px;height: 20px;">
      </a>

    </span>
    <div class="front">
      <a *ngIf="product.StockVariants[0].Images.length"
        [routerLink]="[routerurl+ product?.URL + '-' + VariantCode | localize]">
        <img [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'"
          [lazyLoad]="ImageSrc ? ImageSrc : product.StockVariants[0].Images[0].ImageLocation"
          class="img-fluid lazy-loading active-image1" />


        <img [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'"
          *ngIf="!product.StockVariants[0].Images[1]"
          [lazyLoad]="ImageSrc2 ? ImageSrc2 : product.StockVariants[0].Images[0].ImageLocation"
          class="img-fluid lazy-loading active-image2" />
        <img [defaultImage]="ImageSrc ? ImageSrc : 'assets/images/product/placeholder.jpg'"
          *ngIf="product.StockVariants[0].Images[1]"
          [lazyLoad]="ImageSrc2 ? ImageSrc2 : product.StockVariants[0].Images[1].ImageLocation"
          class="img-fluid lazy-loading active-image2" />
      </a>
    </div>
    <div class="back" *ngIf="onHowerChangeImage">
      <a *ngIf="product.StockVariants" [routerLink]="[routerurl+ product?.URL + '-' + VariantCode | localize]">
        <img [src]="ImageSrc ? ImageSrc : product.StockVariants[0].Images[0].ImageLocation"
          class="img-fluid lazy-loading">
      </a>
    </div>

    <div class="cart-info cart-wrap product-size product-size-responsive-none"
      style="display: flex;align-items: center;">
      <div class="cart-effect-left">
        <a href="javascript:void(0)" style="text-align: right;" (click)="QuickView.openModal()" class="cart">
          <i class="ti-eye" style="font-size: 19px;font-weight: 400;color: black;"></i>
        </a>
      </div>


      <a href="javascript:void(0)" style="text-align: right;" (click)="addToCart(product)" class="cart">
        <i class="ti-shopping-cart" style="font-size: 19px;font-weight: 400;color: black;"></i>
      </a>
    </div>
    <div class="cart-info cart-wrap product-size product-size-responsive-block" *ngIf="!gridFlex"
      style="display: flex;align-items: center;" id="cart-info">
      <div class="cart-effect-left">
        <a href="javascript:void(0)" style="text-align: right;" (click)="QuickView.openModal()" class="cart">
          <i class="ti-eye"
            style="font-size: 15px;font-weight: 400;color: black;padding-right: 5px;padding-left: 5px;"></i>
        </a>
      </div>

      <a href="javascript:void(0)" style="text-align: right;" (click)="addToCart(product)" class="cart">
        <i class="ti-shopping-cart"
          style="font-size: 15px;font-weight: 400;color: black;padding-right: 5px;padding-left: 5px;"></i>
      </a>


    </div>
    <div class="cart-info cart-wrap product-size product-size-responsive-flex" *ngIf="gridFlex"
      style="display: flex;align-items: center;" id="cart-info">
      <div class="cart-effect-left">
        <a href="javascript:void(0)" style="text-align: right;" (click)="QuickView.openModal()" class="cart">
          <i class="ti-eye" style="font-size: 19px;font-weight: 400;color: black;"></i>
        </a>
      </div>


      <a href="javascript:void(0)" style="text-align: right;" (click)="addToCart(product)" class="cart">
        <i class="ti-shopping-cart" style="font-size: 19px;font-weight: 400;color: black;"></i>
      </a>
    </div>
  </div> -->
  <div class="product-detail">
    <div class="row">
      <div class="col-lg-11">
        <div class="row list-view-div">
          <div class="col-lg-3 mb-4">
            <label class="label-product">Ürün Kodu</label>
            {{ product?.Code }}
          </div>
          <div class="col-lg-3 mb-4">
            <label class="label-product">Ürün Adı</label>
            <a [routerLink]="[routerurl + product?.URL + '-'  + product?.StockVariants[0].VariantCode | localize]">
              <h6>{{ product?.Name }}</h6>
            </a>
          </div>
          <div class="col-lg-3 mb-4">
            <label class="label-product">Stok</label>
            {{ProductVariantCode.Balance}} {{'cart:cart-table-quantity' | translate}}
          </div>
          <div class="col-lg-3 mb-4">
            <label class="label-product">Modeller</label>
            <ul class="color-variant" id="color-variant{{product?.Code}}" *ngIf="Color(product?.StockVariants).length">
              <li *ngFor="let color of Color(product?.StockVariants);let first = first;"
                [ngClass]="{'color-variant-active': first}" style="margin-right: 5px;margin-bottom: 5px;padding: 3px;">
                <a class="color-variant-color" (click)="ChangeVariants(color.ColorName, product,$event,product?.Code)"
                  [ngStyle]="{'background-color': color.HexCode}">
                  <button class="color-variant-button">{{color.ColorName}}</button>
                </a>
              </li>
            </ul>
          </div>
          <div class="col-lg-3 mb-4">
            <label class="label-product">Liste Fiyatı</label>
            <ng-container *ngIf="tokenService.getUser()">
              <h4>{{ product?.CardStoreSalesPrice | currency: ' ':'symbol':'.2-4':'it' }}</h4>
            </ng-container>
            <a *ngIf="!tokenService.getUser()" [ngClass]="{'only-member-gridflex': !gridFlex}" class="only-member"
              [routerLink]="[routerurl + product?.URL + '-'  + product?.StockVariants[0].VariantCode | localize]">
              {{'product:product-only-member' | translate}}
            </a>
          </div>

          <div class="col-lg-3 mb-4">
            <label class="label-product">İskonto</label>
            <span
              *ngIf="ProductVariantCode.Balance != 0 && product?.CardStoreSalesPrice != product?.OldSalesPrice && product?.CardStoreSalesPrice < product?.OldSalesPrice"
              class="sale-percent">
              <img alt="" src="assets/images/icon/two-down-arrows.svg"
                style="width: 12px;;margin-right: 5px;filter: invert(1);">{{ ((product?.OldSalesPrice /
              product?.CardStoreSalesPrice )*100) - 100 | number: '1.0-0' }}%</span>
          </div>
          <div class="col-lg-3 mb-4">
            <label class="label-product">KDV</label>

          </div>

          <div class="col-lg-3 mb-4">
            <label class="label-product">Fiyat</label>
            <ng-container>
              <h4
                *ngIf="tokenService.getUser() && product?.CardStoreSalesPrice == product?.OldSalesPrice || product?.CardStoreSalesPrice > product?.OldSalesPrice">
                {{ product?.CardStoreSalesPrice | currency: ' ':'symbol':'.2-4':'it' }}</h4>
              <h4
                *ngIf="tokenService.getUser() && product?.CardStoreSalesPrice != product?.OldSalesPrice && product?.CardStoreSalesPrice < product?.OldSalesPrice"
                style="color: #A83151;font-size: 16px;">
                <del style="font-size: 14px;color: #aaa;"><span class="money">{{ product?.OldSalesPrice | currency: '
                    ':'symbol':'.2-4':'it' }}</span></del>
                {{ product?.CardStoreSalesPrice | currency: ' ':'symbol':'.2-4':'it' }} (-{{ ((product?.OldSalesPrice /
                product?.CardStoreSalesPrice )*100) - 100 | number: '1.0-0' }}%)
              </h4>
            </ng-container>
            <a *ngIf="!tokenService.getUser()" [ngClass]="{'only-member-gridflex': !gridFlex}" class="only-member"
              [routerLink]="[routerurl + product?.URL + '-'  + product?.StockVariants[0].VariantCode | localize]">
              {{'product:product-only-member' | translate}}
            </a>
          </div>
        </div>
      </div>
      <div class="col-lg-1">
        <label class="label-product">İşlemler</label>
        <div class="row">
          <div class="col-4">
            <span class="wishlist">
              <a href="javascript:void(0)" *ngIf="!Wishlist()" style="vertical-align: middle;color: black;"
                title="{{'wishlist:wishlist-add-to-wishlist' | translate}}" (click)="addToWishlist(product)">
                <img src="assets/images/nullwishlist.svg" style="width: 20px;height: 20px;">
              </a>
              <a href="javascript:void(0)" *ngIf="Wishlist()" style="color: black;"
                title="{{'wishlist:wishlist-add-to-wishlist' | translate}}" (click)="removeToWishlist(product)">
                <img src="assets/images/wishlist.svg" style="width: 20px;height: 20px;">
              </a>
            </span>
          </div>
          <div class="col-4">
            <div class="cart-effect-left">
              <a href="javascript:void(0)" style="text-align: right;"  class="cart">
                <i class="ti-eye" style="font-size: 19px;font-weight: 400;color: black;"></i>
              </a>
            </div>
          </div>
          <div class="col-4">
            <a href="javascript:void(0)" style="text-align: right;" (click)="addToCart(product)" class="cart">
              <i class="ti-shopping-cart" style="font-size: 19px;font-weight: 400;color: black;"></i>
            </a>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
<app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>